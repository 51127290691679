import LocaleStrings from "../../interfaces/LocaleStrings";
import parse from "html-react-parser";

const fr  : LocaleStrings = {
  title: "Maison de Vacances Sa Ferula - de Maria Rocchitta",
  footer: {
    buttons: {
      homepage: "Accueil",
      about: "Sa Ferula",
      services: "Chambres et services",
      prices: "Tarifs",
      pictures: "Pictureses",
      reach: "Rnez-gnez-nous",
    },
  },
  homepage: {
    subtitle: "Maison de vacances",
    title: "Sa Ferula",
  },
  about: {
    title: "Bienvenue à Sa Ferula!",
    subtitle:   parse(`La maison de vacances "Sa Ferula" se trouve au premier étage d'une villa avec jardin située à Flumini,
                        hameau de Quartu Sant'Elena, à seulement 300 metres de la mer.
                        Vous pourrez vous rendre à pied aussi vers un supermarché fourni, bar, pizzeria,
                        rôtisseries, bureau de poste, église et discoteques, et vous purrez vous détendre à la
                        paix de la campagne en prenant le soleil en jardin ou en allant à la plage.
                        Vous serez accueillis par les propriétaires avec amitié et sympathie. Du 1er juin au 30 septembre.`),
    rooms: "Les chambres",
    roomsAmenities: [
      "Deux chambres doubles avec salles de bains privées",
      "Jusqu'à 5 personnes",
      "Cuisine complète",
      "Ventilateur de plafond dans chaque chambre",
      "Télévision",
    ],
    bathrooms: "Les salles de bains",
    bathroomsAmenities: [
      "Serviettes",
      "Sèche-cheveux",
      "Savons et shampoings",
    ],
    garden: "Le jardin",
    gardenAmenities: [
      "Parking privé",
      "Gazebo",
      "Accès 24h"
    ]
  },
  prices: {
    title: "Tarifs",
    priceTable: {
      title: "Prix pour la saison {{year}} (par nuit)",
      tipology: "Typologie",
      june: "Juin",
      july: "Juil",
      august: "Août",
      september: "Sept",
      twoPersons: "2 personnes",
      threePersons: "3 personnes",
      fourPersons: "4 personnes",
      fivePersons: "5 personnes",
      children: "Enfants (5 à 12 ans)",
    },
    minimumStay: "En <strong>août</strong> on accept seulement réservation à partir de <strong>7 nuits</strong>.",
    howToBook: parse("Comme réserver votre vacance"),
    howToBookList: [
      parse("<strong>Contactez nous</strong> par téléphone, par e-mail ou à travers notre site Web pour nous demander la <strong>disponibilité</strong> et un <strong>devis</strong>;"),
      parse("Envoyez nous un e-mail de <strong>confirmation</strong> et faitez un dépôt égal aux deux premières nuits, par <strong>remise bancaire</strong> sur le compte n° IBAN que nous vous communiquerons;"),
      parse("Expédiez nous par email la <strong>photocopie</strong> de paiement effectué."),
    ],
    remainderUponArrival: parse("Le solde sera payé uniquement en <strong>comptant</strong>"),
    cancelBook: parse("Annulation de la réservation"),
    cancelBookParagraph: parse("En cas de <strong>annulation</strong> avertez nous en temps utile par <strong>téléphone</strong> ou par <strong>email</strong>:"),
    cancelBookList: [
      parse("Dans sept jours de la confirmation: seront retenus seulement les frais bancaires;"),
      parse("Après sept jours de la confirmation: sera retenu l'entier acompte."),
    ],
  },
  reach: {
    title: "Rejoignez-nous",
    byCarAirport: "En voiture (de l'aéroport de Elmas)",
    byCarAirportDesc: [
      "Prenez la S.S. 554 en direction Quartu Sant'Elena;",
      "Au grand rond-point (Margine Rosso) prenez la troisième sortie en direction Villasimius (Route du littoral Viale Leonardo da Vinci, S.P. 17);",
      "Après environ 6km tournez à droite dans Via Mar Egeo: le maison se trouve au numéro 11 (ruelle sur la gauche)."
    ],
    byBusAirport: "En bus (de l'aéroport de Elmas)",
    byBusAirportDesc: [
      "Prenez le train à la gare de l'aéroport en direction de Cagliari;",
      "Prenez l'autobus \"PF\" au terminus CTM en direction Flumini (Hameau de Quartu Sant'Elena);",
      "Descendre à l'arrêt \"Leonardo da Vinci, Scuole\" et revenez en arrière quelques mètres pour prendre Via Mar Egeo;",
      "Marchez pour environ 150m et prenez la ruelle sur la gauche au numero 11."
    ],
    byCarSeaport: "En voiture (du port de Cagliari)",
    byCarSeaportDesc: [
      "Sortez du port et dirigez vous vers Viale C. Colombo;",
      "Continuez tout droit jusqu'au rond-point (Sant'Elia), prenez la troisième sortie et au feu tournez à droite dans Viale Poetto;",
      "Parcourez la rue jusqu'au rond-point (Marina Piccola) et prenez la troisième sortie (Viale Lungomare del Golfo);",
      "Continuez pour environ 8 km jusqu'à la fin de la plage du Poetto, où se trouve le grand rond-point (Margine Rosso) et prenez la premiere sortie à droite en direction Villasimius (Route du littoral Viale Leonardo da Vinci, S.P. 17);",
      "Après environ 6km tournez à droite dans Via Mar Egeo: le maison se trouve au numéro 11 (ruelle sur la gauche)."
    ],
    byBusSeaport: "En bus (Du port de Cagliari)",
    byBusSeaportDesc: [
      "Allez vers le terminus CTM dans Piazza Matteotti;",
      "Prenez l'autobus \"PF\" au terminus CTM en direction Flumini (Hameau de Quartu Sant'Elena);",
      "Descendre à l'arrêt \"Leonardo da Vinci, Scuole\" et revenez en arrière quelques mètres pour prendre Via Mar Egeo;",
      "Marchez pour environ 150m et prenez la ruelle sur la gauche au numero 11."
    ],
  }
};

export default fr;
