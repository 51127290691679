import React, { useContext } from 'react';

import './App.scss';

import AppContext from './AppContext';

import Header from './Header/Header';

import Homepage from './Pages/Homepage/Homepage';
import About from './Pages/About/About';
import Prices from './Pages/Prices/Prices';
import Reach from './Pages/Reach/Reach';
import Footer from './Pages/Footer/footer';


function App() {
  const appContext = useContext(AppContext);

  return (
    <div id="app-container">
      <Header
        address={appContext.constants.address}
        phone={appContext.constants.phone}
        email={appContext.constants.email}
      />
      <div id="main-content">
        <div className="page-content">
          <Homepage />
          <About />
          <Prices />
          <Reach />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
