import React, { useContext } from 'react';
import './Prices.scss';
import AppContext from '../../AppContext';
import parse from "html-react-parser";



const Prices: React.FC = () => {
    const { strings, config } = useContext(AppContext) || {};

    const prices = config?.season.prices;
    const year = String(config?.season.year);
    const minimumStay = String(config?.season.minimumStay);


    return (
        <div className="container">
            <main id="prices">
                <h1>{strings?.prices.title}</h1>
                <h2>{typeof strings?.prices.priceTable.title === 'string' ? strings?.prices.priceTable.title.replace('{{year}}', year) : strings?.prices.priceTable.title}</h2>
                <table>
                    <thead>
                        <tr>
                            <th>{strings?.prices.priceTable.tipology}</th>
                            <th>{strings?.prices.priceTable.june}</th>
                            <th>{strings?.prices.priceTable.july}</th>
                            <th>{strings?.prices.priceTable.august}</th>
                            <th>{strings?.prices.priceTable.september}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{strings?.prices.priceTable.twoPersons}</td>
                            <td>€ {prices?.twoPersons.june}</td>
                            <td>€ {prices?.twoPersons.july}</td>
                            <td>€ {prices?.twoPersons.august}</td>
                            <td>€ {prices?.twoPersons.september}</td>
                        </tr>
                        <tr>
                            <td>{strings?.prices.priceTable.threePersons}</td>
                            <td>€ {prices?.threePersons.june}</td>
                            <td>€ {prices?.threePersons.july}</td>
                            <td>€ {prices?.threePersons.august}</td>
                            <td>€ {prices?.threePersons.september}</td>
                        </tr>
                        <tr>
                            <td>{strings?.prices.priceTable.fourPersons}</td>
                            <td>€ {prices?.fourPersons.june}</td>
                            <td>€ {prices?.fourPersons.july}</td>
                            <td>€ {prices?.fourPersons.august}</td>
                            <td>€ {prices?.fourPersons.september}</td>
                        </tr>
                        <tr>
                            <td>{strings?.prices.priceTable.fivePersons}</td>
                            <td>€ {prices?.fivePersons.june}</td>
                            <td>€ {prices?.fivePersons.july}</td>
                            <td>€ {prices?.fivePersons.august}</td>
                            <td>€ {prices?.fivePersons.september}</td>
                        </tr>
                        <tr>
                            <td>{strings?.prices.priceTable.children}</td>
                            <td colSpan={5}>€ {prices?.children}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="info blue">{ parse(strings?.prices.minimumStay?.replace('{{days}}', minimumStay))}</div>
                <div id="howto" className="lists">
                    <article>
                        <h1>{strings?.prices.howToBook}</h1>
                        <ul>
                            {strings?.prices.howToBookList.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                        <div className="info red">{strings?.prices.remainderUponArrival}</div>
                    </article>
                    <article>
                        <h1>{strings?.prices.cancelBook}</h1>
                        <p>{strings?.prices.cancelBookParagraph}</p>
                        <ul>
                            {strings?.prices.cancelBookList.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </article>
                </div>
            </main>
        </div>
    );
};

export default Prices;