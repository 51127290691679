import LocaleStrings from "../../interfaces/LocaleStrings";
import parse from "html-react-parser";

const en : LocaleStrings = {
  title: "Holiday House Sa Ferula - by Maria Rocchitta",
  footer: {
    buttons: {
      homepage: "Homepage",
      about: "Sa Ferula",
      services: "Rooms and services",
      prices: "Prices",
      pictures: "Pictures",
      reach: "How to reach us",
    },
  },
  homepage: {
    subtitle: "Holiday house",
    title: "Sa Ferula",
  },
  about: {
    title: "Welcome to Sa Ferula!",
    subtitle: parse(`The holiday home "Sa Ferula" is located in a private villa with garden located in Flumini, a hamlet of Quartu Sant'Elena, and is only 300 meters from the sea. You can also walk to a well-stocked supermarket, bars, pizzerias, rotisseries, post office, church and discos, and you can relax in the peace of the countryside sunbathing in the garden or going to the beach. You will be hosted by the owners with friendship and sympathy. From June 1st to September 30th.`),
    rooms: "The rooms",
    roomsAmenities: [
      "Two double bedrooms with private bathrooms",
      "Up to 5 peoples",
      "Full kitchen",
      "Ceiling fan in every room",
      "TV",
    ],
    bathrooms: "The bathrooms",
    bathroomsAmenities: [
      "Towels",
      "Hairdryer",
      "Soaps and shampoos",
    ],
    garden: "The garden",
    gardenAmenities: [
      "Private parking",
      "Gazebo",
      "Access 24h"
    ]
  },
  prices: {
    title: "Prices",
    priceTable: {
      title: "Prices for season {{year}} (per night)",
      tipology: "Tipology",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      twoPersons: "2 people",
      threePersons: "3 people",
      fourPersons: "4 people",
      fivePersons: "5 people",
      children: "Children (5 to 12 years old)",
    },
    minimumStay: "In <strong>August</strong> the minimum stay is <strong>{{days}} night</strong>",
    howToBook: "How to make a reservation",
    howToBookList: [
      parse("<strong>Contact us</strong> by email or from our site and ask for <strong>availability</strong> and a <strong>quote</strong>;"),
      parse("Send us an e-mail <strong>confirmation</strong> and make a deposit of the amount for the first two nights by <strong>bank transfer</strong> to the IBAN we will give you;"),
      parse("Send us a <strong>photocopy</strong> of the payment document by e-mail.")
    ],
    remainderUponArrival: parse("the balance must be paid within 7 days of the date of stay via <strong>bank transfer only</strong>"),
    cancelBook: "Cancellation of the reservation",
    cancelBookParagraph: parse("In case of <strong>cancellation</strong> please immediately notify us of it by <strong>email</strong>:"),
    cancelBookList: [
      parse("Within 7 days of confirmation: only the bank charges will be retained;"),
      parse("More than 7 days after confirmation: the entire deposit will be retained."),
    ],
  },
  reach: {
    title: "How to reach us",
    byCarAirport: "By car (From the Elmas Airport)",
    byCarAirportDesc: [
      "Take the S.S. 554 towards Quartu Sant'Elena;",
      "At the great roundabout (Margine Rosso) take the third exit towards Villasimius (Litoranea Viale Leonardo da Vinci, S.P. 17);",
      "After about 6km turn right into Via Mar Egeo. The holiday house is located at number 11 (left side)."
    ],
    byBusAirport: "By bus (from the Elmas Airport)",
    byBusAirportDesc: [
      "Take the train at the airport station with destination Cagliari;",
      "At the CTM terminus take the \"PF\" bus in the direction of Flumini (Frazione di Quartu Sant'Elena);",
      "Get off at the \"Leonardo da Vinci, Scuole\" stop and go back a few meters into Via Mar Egeo;",
      "Walk for about 150m. The holiday house is located at number 11 (left side)."
    ],
    byCarSeaport: "By car (From the Port of Cagliari)",
    byCarSeaportDesc: [
      "Leave the harbor and head to Viale C. Colombo;",
      "Continue straight until the roundabout (Sant'Elia), take the third exit and at the traffic light turn right into Viale Poetto;",
      "Reach the roundabout (Marina Piccola) and take the third exit (Viale Lungomare del Golfo);",
      "Continue for approximately 8km until you reach the end of the Poetto beach. You'll find a large roundabout (Margine Rosso); take the first exit towards Villasimius (Litoranea Viale Leonardo da Vinci, S.P. 17);",
      "After about 6km turn right into Via Mar Egeo. The holiday house is located at number 11 (left side)."
    ],
    byBusSeaport: "By bus (from the Port of Cagliari)",
    byBusSeaportDesc: [
      "Reach the CTM terminus in Piazza Matteotti;",
      "Take the \"PF\" bus at the CTM terminus in the direction of Flumini (Frazione di Quartu Sant'Elena);",
      "Get off at the \"Leonardo da Vinci, Scuole\" stop and go back a few meters into Via Mar Egeo;",
      "Walk for about 150m. The holiday house is located at number 11 (left side)."
    ],
  }
};

export default en;
