import LocaleStrings from "../../interfaces/LocaleStrings";
import parse from "html-react-parser";

const it : LocaleStrings = {
  title: "Casa Vacanze Sa Ferula - di Maria Rocchitta",
  footer: {
    buttons: {
      homepage: "Homepage",
      about: "Sa Ferula",
      services: "Camere e Servizi",
      prices: "Tariffe",
      pictures: "Foto",
      reach: "Raggiungici",
    },
  },
  homepage: {
    subtitle: "Casa vacanze",
    title: "Sa Ferula",
  },
  about: {
    title: "Benvenuti a Sa Ferula!",
    subtitle: "La casa vacanze \"Sa Ferula\" si trova in una villa privata con giardino situata a Flumini, frazione di Quartu Sant'Elena, e dista soltanto 300 metri dal mare. Potrete spostarvi anche a piedi verso un supermercato fornito, bar, pizzerie, rosticcerie, ufficio postale, chiesa e discoteche, e potrete rilassarvi nella pace della campagna prendendo il sole in giardino o recandovi alla spiaggia. Sarete ospitati dai padroni di casa con amicizia e simpatia. Dal 1 Giugno al 30 Settembre.",
    rooms: "Le camere",
    roomsAmenities: [
      "Due camere da letto matrimoniali con bagni privati",
      "Fino a 5 posti letto",
      "Cucina completa",
      "Ventola a soffitto in ogni camera",
      "Televisore",
    ],
    bathrooms: "I bagni",
    bathroomsAmenities: [
      "Asciugamani",
      "Asciugacapelli",
      "Saponi e shampoo",
    ],
    garden: "Il giardino",
    gardenAmenities: [
      "Parcheggio privato",
      "Gazebo",
      "Accesso 24h"
    ]
  },
  prices: {
    title: "Tariffe",
    priceTable: {
      title: "Prezzi per la stagion {{year}} (a notte)",
      tipology: "Tipologia",
      june: "Giugno",
      july: "Luglio",
      august: "Agosto",
      september: "Settembre",
      twoPersons: "2 persone",
      threePersons: "3 persone",
      fourPersons: "4 persone",
      fivePersons: "5 persone",
      children: "Bambini (dai 5 ai 12 anni)",
    },
    minimumStay: "Ad agosto si accettano solo prenotazioni di minimo <strong>{{days}} notti</strong>",
    howToBook: "Come prenotare la vacanza",
    howToBookList: [
      parse("<strong>Contattaci</strong> telefonicamente, via email o tramite il nostro sito per chiederci la <strong>disponibilità</strong> e un <strong>preventivo</strong>"),
      parse("Inviaci un email di <strong>conferma</strong> ed effettua un versamento pari alle prime due notti, tramite <strong>bonifico bancario</strong> all'IBAN che ti comunicheremo"),
      parse("Spedisci via email la <strong>fotocopia</strong> di avvenuto pagamento"),
    ],
    remainderUponArrival: parse("Il saldo verrà corrisposto <strong>esclusivamente via bonifico</strong> 7 giorni prima dall'arrivo"),
    cancelBook: "Annullamento della prenotazione",
    cancelBookParagraph: parse("In caso di <strong>annullamento</strong> avvisaci tempestivamente tramite <strong>telefono</strong> o via <strong>email</strong>:"),
    cancelBookList: [
      parse("Entro 7 giorni dalla conferma: verranno trattenute le sole spese bancarie"),
      parse("Oltre 7 giorni dalla conferma: verrà trattenuto l'intero acconto"),
    ],
  },
  reach: {
    title: "Come Raggiungerci",
    byCarAirport: "Via auto (dall'aeroporto di Elmas)",
    byCarAirportDesc: [
      "Prendi la S.S. 554 in direzione Quartu Sant'Elena",
      "Alla grande rotatoria (Margine Rosso) prendi la terza uscita in direzione Villasimius (Litoranea Viale Leonardo da Vinci, S.P. 17)",
      "Dopo circa 6km gira a destra in Via Mar Egeo: la casa vacanze si trova al numero 11 (vicolo sulla sinistra)"
    ],
    byBusAirport: "Via bus (dall'aeroporto di Elmas)",
    byBusAirportDesc: [
      "Prendi il treno alla stazione dell'aeroporto con destinazione Cagliari",
      "Prendi l'autobus \"PF\" al capolinea CTM in direzione Flumini (Frazione di Quartu Sant'Elena)",
      "Scendi alla fermata \"Leonardo da Vinci, Scuole\" e torna indietro di pochi metri per imboccare Via Mar Egeo",
      "Cammina per circa 150m e imbocca il vicolo sulla sinistra al numero 11"
    ],
    byCarSeaport: "Via auto (dal porto di Cagliari)",
    byCarSeaportDesc: [
      "Esci dal porto in via Roma (lato mare) e dirigiti verso Viale C. Colombo",
      "Prosegui diritto sino alla rotatoria (Sant'Elia), prendi la terza uscita e al semaforo gira a destra in Viale Poetto",
      "Percorri la via sino alla rotatoria (Marina Piccola) e prendi la terza uscita (Viale Lungomare del Golfo)",
      "Prosegui per circa 8 km sino alla fine della spiaggia del Poetto, dove si trova la grande rotatoria (Margine Rosso) e prendi la prima uscita a destra in direzione Villasimius (Litoranea Viale Leonardo da Vinci, S.P. 17)",
      "Dopo circa 6km gira a destra in Via Mar Egeo: il la casa vacanze si trova al numero 11 (vicolo sulla sinistra)"
    ],
    byBusSeaport: "Via bus (dal porto di Cagliari)",
    byBusSeaportDesc: [
      "Dirigiti verso il capolinea CTM in Piazza Matteotti",
      "Prendi l'autobus \"PF\" al capolinea CTM in direzione Flumini (Frazione di Quartu Sant'Elena)",
      "Scendi alla fermata \"Leonardo da Vinci, Scuole\" e torna indietro di pochi metri per imboccare Via Mar Egeo",
      "Cammina per circa 150m e imbocca il vicolo sulla sinistra al numero 11"
    ],
  }
};

export default it;
