import { useEffect, useState } from 'react';

function useConfig() {
  const [config, setConfig] = useState(() => {
    // Try to load the config from localStorage
    const storedConfig = localStorage.getItem('config');
    return storedConfig ? JSON.parse(storedConfig) : null;
  });

  useEffect(() => {
    if (!config) {
      // If the config is not in the state, fetch it
      fetch('./config.json')
        .then((response) => response.json())
        .then((data) => {
          setConfig(data);
        })
        .catch((error) => console.error('Error loading the config file', error));
    }
  }, [config]);

  return config;
}

export default useConfig;