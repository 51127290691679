import React, { useContext } from 'react';
import './Homepage.scss';
import AppContext from '../../AppContext';
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import BackgroundSlider from 'react-background-slider';

const Homepage: React.FC = () => {
    const context = useContext(AppContext);

    //transform images into a format that the slider can use
    const imgs = context.constants.slideshow.map((image: string) => {
        return {
            url: image,
            alt: ''
        }
    });

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }



    return (
        <div id="homepage">
            <div id="slider">
                <Fade>
                    {imgs.map((slideImage: any, index: number) => (
                        <div key={index}>
                            <div className="background-slider" style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                
                            </div>
                        </div>
                    ))}
                </Fade>
            </div>
            <div id="logo">
                <h2>{context.strings.homepage.subtitle}</h2>
                <h1>{context.strings.homepage.title}</h1>
            </div>

        </div>
    );
}

export default Homepage;