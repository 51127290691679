// src/contexts/MyContext.tsx
import React from 'react';
import LocaleStrings from './interfaces/LocaleStrings';
import Config from './interfaces/Config';

import en from './Constants/locale/strings.en';

// Definire il tipo per il valore del contesto
interface IAppContext {
  lang: string;
  setLang: (newValue: string) => void;
  strings: LocaleStrings;
  constants?: any;
  config?: Config
}

// Define a default context value
const defaultContext: IAppContext = {
  lang: 'en', // default language
  setLang: () => {}, // default function that does nothing
  strings: en, // default empty object
  constants: {}, // default empty object
   // default empty object
};

// Creare il Context con un valore predefinito
const AppContext = React.createContext<IAppContext>(defaultContext);

export default AppContext;
